import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
        --primary-color: #FF0102;
        --secondary-color: ##F4CF3F;
        --dark-bg: #ba0404;
        --dark-bg-txt: #FF893B;
        --first-color: #f1ca65;
        --second-color: #9fbb65;
        --third-color: #5aa374;
        --fourth-color: #26867c;
        --fifth-color: #216773;
        --sixth-color: #2f4858;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: "Open Sans", sans-serif;
        // font-family: "Roboto", serif;
    }

    html, body {
        overflow-x: hidden;
    }

    .heroTitle {
        color: var(--primary-color);
        text-align: center;
    }

    .scrolled {
        background-color: rgba(235, 55, 52, 0.8);
        transition:500ms ease;
        // background: linear-gradient(90deg, rgb(224, 107, 28) 0%, rgb(0, 140, 148) 100%)
    }
`;

export default GlobalStyle;

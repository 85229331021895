import React from "react";
import styled from "styled-components";
// import { Button } from "./Button";
// import roomImg from "../images/hq.png";

const InfoDiv = styled.section`
  width: 100%;
  height: 100%;
  padding: 1rem 0rem;
  margin: 0 auto;

  @media screen and (max-width: 480px) {
    width: 95%;
    padding: 0rem;
    margin: 0 auto;
  }
`;

const Container = styled.div`
  padding: 1rem calc((100vw - 1300px) / 2);
  margin: 0.5rem auto 0;
  display: grid;
  grid-template-columns: 1fr;

  h1 {
    color: #f4cf3f;
    margin-bottom: 1rem;
    font-size: clamp(1.5rem, 6vw, 2rem);
    text-align: center;
    font-weight: bold;
  }

  p {
    margin-bottom: 2rem;
    text-align: center;
  }

  h4 {
    color: #94410a;
    margin-top: 2rem;
  }

  ul {
    margin: 0.5rem auto;
    line-height: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  li {
    text-decoration: none;
    color: var(--dark-bg-txt);
    list-style: square;
    margin: 0 1.5rem;
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    padding: 0;
    margin: 0 auto;
    gap: 0;
  }
`;

const InfoSection = () => {
  return (
    <InfoDiv>
      <Container>
        <h1>Introduction</h1>
        <p>
          SD Pasali Oil and Gas Nigeria Limited, founded in 2016, is a prominent
          and trusted player in the Nigerian oil and gas industry. Our
          headquarters are strategically located in the vibrant city of Jos,
          Plateau State, Nigeria, and we have rapidly gained recognition for our
          unwavering commitment to excellence, safety, and customer
          satisfaction. Our mission and vision drive every facet of our
          operations, reinforcing our dedication to powering progress while
          upholding environmental responsibility, fostering employee
          development, and contributing to the well-being of society.
        </p>
        {/* <Button to="/" primary="false">
          Read More
        </Button> */}
      </Container>
    </InfoDiv>
  );
};

export default InfoSection;

import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import SideBar from "./components/SideBar";
import GlobalStyle from "./globalStyles";
import "bootstrap/dist/css/bootstrap.min.css";

import Home from "./pages/Home";
import ScrollToTop from "./ScrollToTop";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <>
        <ScrollToTop />
        <GlobalStyle />
        <Navbar toggle={toggle} />
        <SideBar isOpen={isOpen} toggle={toggle} />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/contact/" element={<Contact />} /> */}
        </Routes>
        <Footer />
      </>
    </>
  );
}

export default App;

import hq from "../images/hq.png";
import branch from "../images/branch.png";
import trucks from "../images/trucks.png";

export const SliderData = [
  {
    title: "",
    price: "#",
    path: "/",
    label: "",
    image: hq,
    alt: "SD Pasali Oil & Gas Nig. Ltd.",
  },
  {
    title: "",
    price: "#",
    path: "/",
    label: "",
    image: branch,
    alt: "SD Pasali Oil & Gas Nig. Ltd.",
  },
  {
    title: "",
    price: "#",
    path: "/",
    label: "",
    image: trucks,
    alt: "SD Pasali Oil & Gas Nig. Ltd.",
  },
];

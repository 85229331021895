import React from "react";
import Hero from "../components/Hero";
import { SliderData } from "../data/SliderData";
import InfoSection from "../components/InfoSection";
import Products from "../components/Products";

const Home = () => {
  return (
    <>
      <Hero slides={SliderData} />
      <InfoSection />
      <Products />
    </>
  );
};

export default Home;

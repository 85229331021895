import React from "react";
import styled from "styled-components";
import pms from "../images/Fuel-Petrol.jpg";
import ago from "../images/Diesel-pump.jpg";
import dpk from "../images/kerosene.jpg";
import lpg from "../images/Cooking-gas.jpg";

const InfoDiv = styled.section`
  width: 100%;
  height: 100%;
  padding: 1rem 0rem;
  margin: 0.5rem auto;

  h2 {
    font-weight: bold;
    color: #f4cf3f;
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
    padding: 0rem;
    margin: 0 auto;
  }
`;

const Container = styled.div`
  padding: 1rem calc((100vw - 1300px) / 2);
  margin: 0.5rem auto 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    padding: 0;
    margin: 0 auto;
    gap: 0;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  img {
    width: 300px;
  }

  h5 {
    color: #0c4fed;
  }

  @media screen and (max-width: 760px) {
    flex-direction: column;
    padding: 0;
    margin: 0 auto;
    gap: 0;
  }
`;

const Products = () => {
  return (
    <InfoDiv>
      <h2>Petroleum Products</h2>
      <Container>
        <Card>
          <div>
            <img src={pms} alt="" />
          </div>
          <div>
            <h5>Premium Motor Spirit (PMS)</h5>
            <p>
              Our Premium Motor Spirit (PMS) stands as a testament to our
              commitment to quality. Trusted by motorists and industries alike,
              our PMS is a reliable source of high-performance fuel.
            </p>
          </div>
        </Card>
        <Card>
          <div>
            <img src={ago} alt="" />
          </div>
          <div>
            <h5>Automotive Gas Oil (AGO)</h5>
            <p>
              As a leading provider of Automotive Gas Oil (AGO), we fuel the
              engines that drive progress across various sectors, from
              transportation to manufacturing.
            </p>
          </div>
        </Card>
        <Card>
          <div>
            <img src={dpk} alt="" />
          </div>
          <div>
            <h5>Dual-Purpose Kerosene (DPK)</h5>
            <p>
              Our Dual-Purpose Kerosene (DPK) provides clean and efficient
              energy solutions, meeting the diverse needs of households and
              industries alike.
            </p>
          </div>
        </Card>
        <Card>
          <div>
            <img src={lpg} alt="" />
          </div>
          <div>
            <h5>Cooking Gas (LPG)</h5>
            <p>
              Our Cooking Gas, primarily Liquefied Petroleum Gas (LPG), is a
              convenient and eco-friendly choice for cooking and heating.
            </p>
          </div>
        </Card>
      </Container>
    </InfoDiv>
  );
};

export default Products;

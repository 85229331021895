import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../images/logo.png";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const FooterDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 1rem 4rem;
  width: 100%;
  color: #fff;
  background: var(--dark-bg);
  line-height: 1.5rem;

  img {
    width: 120px;
  }

  @media screen and (max-width: 480px) {
    display: grid;
    justify-content: center;
  }

  @media screen and (min-width: 300px) {
    grid-template-columns: 1fr;
    padding: 1.5rem;
  }

  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
    padding: 1.5rem;
  }

  @media screen and (min-width: 850px) {
    grid-template-columns: 1fr 1fr;
    padding: 1.5rem;
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 4rem;
  }
`;
// const First = styled.div`
//   padding 1rem 0;

//   h2 {
//       color: var(--secondary-color);
//   }

// //   img {
// //       width: 100px;
// //   }
// `;
// const Second = styled.div`
//   ul {
//     margin: 0.5rem auto;
//     line-height: 2rem;
//   }

//   li > * {
//     text-decoration: none;
//     color: var(--dark-bg-txt);
//     margin-left: 0.5rem;
//   }

//   li > *:hover {
//     color: var(--primary-color);
//   }
// `;
// const Third = styled.div`
//   display: flex;
//   justify-content: center;

//   ul {
//     display: inline;
//     margin: 0.5rem auto;
//     line-height: 2rem;
//   }

//   li > * {
//     text-decoration: none;
//     color: var(--dark-bg-txt);
//   }

//   li > *:hover {
//     color: var(--primary-color);
//   }
// `;
// const Fourth = styled.div``;
const FootMark = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: var(--dark-bg-txt);
  padding: 0.5rem 2rem 0;
  text-align: center;
  border: 0;
  background: #000;

  .footLink {
    color: #fff;
    text-decoration: none;
  }

  .footLink:hover {
    color: var(--dark-bg-txt);
  }

  @media screen and (max-width: 480px) {
    display: grid;
    justify-content: center;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  alii-align: center;
  height: 100%;
  border_radius: 50%;
  font-size: 2rem;

  & > * {
    margin: 1rem 2rem;
    cursor: pointer;
  }

  & > *:hover {
    background: #fff;
    border_radius: 50%;
  }

  @media screen and (max-width: 480px) {
    & > * {
      margin: 1rem;
    }
  }
`;

const Footer = () => {
  return (
    <div>
      <FooterDiv>
        <div>
          <img src={logo} alt="" />
          <h2
            style={{
              color: "#F4CF3F",
              marginBottom: "1rem",
              fontSize: "1.5rem",
            }}
          >
            Headquarters Address
          </h2>
          <p>SD Pasali Oil and Gas Nigeria Limited.</p>
          <p> 42, Along East Bauchi Road,</p>
          <p>Jos, Plateau State, Nigeria.</p>
        </div>
        <div>
          <h4 style={{ color: "#F4CF3F" }}>Phone</h4>
          <p>
            <b>Main Office Phone:</b> (234) 803 649 8034
          </p>
          <p>
            <b>Customer Service:</b> (234) 803 752 8804
          </p>
          <p>
            <b>Emergency Hotline:</b> (234) 802 070 7015
          </p>

          <h4 style={{ color: "#F4CF3F" }}>Email</h4>
          <p>
            <b>Customer Support:</b> sdpasalicustomeromplaints@gmail.com
          </p>
          <p>
            <b>General Inquiries:</b> sdpasalioinandgasnigltd@gmail.com
          </p>
        </div>
        <div>
          <h4 style={{ color: "#F4CF3F" }}>Operating Hours</h4>
          <p>
            <b>Monday to Friday:</b> 8.00am – 5.00pm
          </p>
          <p>
            <b>Saturday:</b> 12pm – 5.00pm
          </p>
          <p>
            <b>Sunday:</b> 2.00pm – 4.00pm
          </p>

          <SocialIcons>
            <Link to="#">
              <FaFacebook style={{ color: "#1877F2" }} />
            </Link>
            <Link to="#">
              <FaInstagram style={{ color: "#fccc63" }} />
            </Link>

            <Link to="#">
              <FaTwitter style={{ color: "#1DA1F2" }} />
            </Link>
            {/* <Link
                target="_blank"
                to="https://www.instagram.com/thegoodhousephc"
              >
                <FaYoutube style={{ color: "#FF0000" }} />
              </Link> */}
          </SocialIcons>
        </div>
      </FooterDiv>
      <FootMark>
        <p>© Copyright 2024. All Rights Reserved.</p>
        {/* <p>
          <Link className="footLink" to={"/"}>
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link className="footLink" to={"/"}>
            Terms of Use
          </Link>
        </p> */}
        {/* <p>
          <Link className="footLink" to="/">
            {" "}
            Home
          </Link>
          |
          <Link className="footLink" to="/rooms">
            {" "}
            Listings
          </Link>
          |
          <Link className="footLink" to="/events">
            {" "}
            Events
          </Link>
          |
          <Link className="footLink" to="/contact">
            {" "}
            Contact Us
          </Link>
          |
          <Link className="footLink" to="/login">
            {" "}
            Login
          </Link>
        </p> */}
        <p>Nigeria</p>
      </FootMark>
    </div>
  );
};

export default Footer;
